import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import { CountersValue } from '@hh.ru/chatik-integration/lib/CounterUpdater';
import { HhcaptchaType, Otp, RecaptchaType } from '@hh.ru/hhcaptcha';
import { Breakpoint as MagritteBreakpoint } from '@hh.ru/magritte-ui';
import { autoGeneratedReducer, createRootReducer, ReducerObject } from '@hh.ru/redux-create-reducer';
import { NonNullableRootState } from '@hh.ru/redux-create-reducer/lib/useSelectorNonNullable';
import {
    ChatWritePossibility,
    WorkExperience,
    WorkingDay,
    WorkingTimeInterval,
    WorkingTimeMode,
} from '@hh.ru/types-hh-microcore';
import { Breakpoint as BlokoBreakpoint } from 'bloko/common/media';
import { AdditionalDefault, ModelData } from 'bloko/common/tree/types';

import { DeepLinkCommandName } from 'src/components/DeepLinkCommand/types';
import { BannerState, VacancySuitableResumes } from 'src/components/VacancySuitableResumesBanner';
import { loginForm } from 'src/models/LoginForm';
import { Account } from 'src/models/account';
import { accountConnect } from 'src/models/accountConnect';
import { accountConnectResponse } from 'src/models/accountConnectResponse';
import { accountDelete } from 'src/models/accountDelete';
import { accountHistoryExpenditures } from 'src/models/accountHistory/accountHistoryExpenditures';
import { accountHistoryMoney } from 'src/models/accountHistory/accountHistoryMoney';
import { accountHistoryReplenishments } from 'src/models/accountHistory/accountHistoryReplenishments';
import { accountHistoryServices } from 'src/models/accountHistory/accountHistoryServices';
import { accountHistoryActionsData } from 'src/models/accountHistoryActionsData';
import { accountLogin } from 'src/models/accountLogin';
import { accountPhoneVerification } from 'src/models/accountPhone/verification';
import { accountTemporarilyLocked } from 'src/models/accountTemporarilyLocked';
import { AddSkillToResume } from 'src/models/addSkillToResume';
import { agencies } from 'src/models/agencies';
import { agenciesList } from 'src/models/agenciesList';
import { aiAssistantHR } from 'src/models/aiAssistant/aiAssistantHrSettings';
import { ConnectedServiceMap, allServices } from 'src/models/allServices';
import { allowedCountriesForSmsRegistration } from 'src/models/allowedCountriesForSmsRegistration';
import { anonymousDashboard } from 'src/models/anonymousDashboard';
import { anonymousEmployer } from 'src/models/anonymousEmployer';
import { anonymousSignUp } from 'src/models/anonymousSignUp';
import { appLink } from 'src/models/appLink';
import { appPromoPageBackurl } from 'src/models/appPromoPageBackurl';
import { applicantPossibleJobOffers, jobSearchStatusPostChangeModal } from 'src/models/applicant/jobSearchStatus';
import { resumeAdditionalData } from 'src/models/applicant/resume/additionalData/store';
import { applicantResumeArtifacts } from 'src/models/applicant/resume/artifacts/slice';
import { resumeViewBackoffice } from 'src/models/applicant/resume/backoffice/store';
import { applicantSkillsLevelsEditor } from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/slice';
import { resumeExportTypesReducer } from 'src/models/applicant/resume/download/store';
import { resumeEditor } from 'src/models/applicant/resume/editor/store/slice';
import { employersListReducer } from 'src/models/applicant/resume/employersLists/slice';
import { applicantResume } from 'src/models/applicant/resume/slice';
import { ApplicantSuitableVacancyByResumeHash } from 'src/models/applicant/suitableVacancyByResume';
import { applicantActivity } from 'src/models/applicantActivity';
import { applicantActivityEvents } from 'src/models/applicantActivityEvents';
import { ApplicantAssessment } from 'src/models/applicantAssessment';
import { applicantBlacklistEmployers } from 'src/models/applicantBlacklistEmployers';
import { applicantBlacklistVacancy } from 'src/models/applicantBlacklistVacancy';
import { applicantEmployerPoliteness } from 'src/models/applicantEmployerPoliteness';
import { applicantGallery } from 'src/models/applicantGallery';
import { applicantEmployerManagersActivity } from 'src/models/applicantNegotiations/applicantEmployerManagersActivity';
import { applicantNegotiations } from 'src/models/applicantNegotiations/applicantNegotiations';
import { applicantNegotiationsActionsData } from 'src/models/applicantNegotiations/applicantNegotiationsActionsData';
import { applicantNegotiationsCounters } from 'src/models/applicantNegotiations/applicantNegotiationsCounters';
import { vacanciesShort } from 'src/models/applicantNegotiations/vacanciesShort';
import { applicantPaymentServices } from 'src/models/applicantPaymentServices';
import { applicantPortraitCategories } from 'src/models/applicantPortraitCategories';
import { applicantProfileStore } from 'src/models/applicantProfile/store';
import { applicantRenewedAuthExp } from 'src/models/applicantRenewedAuthExp';
import { applicantResponseStreaks } from 'src/models/applicantResponseStreaks';
import { applicantResumeViewHistory } from 'src/models/applicantResumeViewHistory';
import { applicantResumes } from 'src/models/applicantResumes';
import { applicantResumesStatistics } from 'src/models/applicantResumesStatistics';
import { rsvCompetencyData } from 'src/models/applicantRsvVerification';
import { applicantServicesPayment } from 'src/models/applicantServicesPayment';
import { applicantSettingsMenu } from 'src/models/applicantSettingsMenu';
import { applicantSignup } from 'src/models/applicantSignup';
import { applicantSkillsVerificationError } from 'src/models/applicantSkillsVerificationError';
import {
    ApplicantSkillsVerificationMethod,
    ApplicantSkillsVerificationMethods,
} from 'src/models/applicantSkillsVerificationMethods';
import { ApplicantKeyskillVerificationMethod } from 'src/models/applicantSkillsVerificationMethods/skillsVerificationMethodsUpdated';
import { applicantSkillsVerificationResult } from 'src/models/applicantSkillsVerificationResult';
import { applicantSubscriptions } from 'src/models/applicantSubscriptions';
import { applicantUserStatuses } from 'src/models/applicantUserStatuses';
import { applicantVacancyResponseStatuses } from 'src/models/applicantVacancyResponseStatuses';
import { applications } from 'src/models/applications';
import { applyAgreement } from 'src/models/applyAgreement';
import { archivedVacancies } from 'src/models/archivedVacancies';
import { ArticleItemWithImage, ArticleItemWithTopic } from 'src/models/article.types';
import { articleEmployerInfo } from 'src/models/articleEmployerInfo';
import { articleHHATags } from 'src/models/articleHHATags';
import { articlePage } from 'src/models/articlePage';
import { articleRating } from 'src/models/articleRating';
import { articleViews } from 'src/models/articleViews';
import { artifactRules } from 'src/models/artifactRules';
import { assessments } from 'src/models/assessments';
import { auctionConfirmationModal } from 'src/models/auctionConfirmationModal';
import { auctionData } from 'src/models/auctionData';
import { auctionHhtmSourceLabel } from 'src/models/auctionHhtmSourceLabel';
import { authNewEmployerCategories } from 'src/models/authNewEmployerCategories';
import { authNewEmployerFields } from 'src/models/authNewEmployerFields';
import { authNewEmployerInitialValues } from 'src/models/authNewEmployerInitialValues';
import { authPhone } from 'src/models/authPhone';
import { authUrl } from 'src/models/authUrl';
import { autoInvite } from 'src/models/autoInvite';
import { availableVacancyActions } from 'src/models/availableVacancyActions';
import { adsSearchParams } from 'src/models/banners/adsSearchParams';
import { adsVacancyParams } from 'src/models/banners/adsVacancyParams';
import { banners } from 'src/models/banners/banners';
import { blogPage } from 'src/models/blogPage';
import { blogViews } from 'src/models/blogViews';
import { brandSnippetEditor } from 'src/models/brandSnippetEditor';
import { brandSnippets } from 'src/models/brandSnippets';
import { brandSnippetsData } from 'src/models/brandSnippetsData';
import { brandedVacancyTemplates } from 'src/models/brandedVacancyTemplates';
import { brandingPromotion } from 'src/models/brandingPromotion';
import { candidates } from 'src/models/candidates';
import { TopicNegotiationInterviewInfo, candidatesList } from 'src/models/candidatesList';
import { canvacSimilarVacancies } from 'src/models/canvacSimilarVacancies';
import { canvacVacancySalaryData } from 'src/models/canvacVacancySalaryData';
import { CareerPlatformProxyState, CareerPlatformState } from 'src/models/careerPlatformState';
import { chatBot } from 'src/models/chatBot';
import { chatik } from 'src/models/chatik';
import { chatikFloatActivator } from 'src/models/chatikFloatActivator';
import { clickmeAutoCampaignPromotion } from 'src/models/clickme/clickmeAutoCampaignPromotion';
import { clickmeProducts } from 'src/models/clickme/clickmeProducts';
import { clickmeVacancyPage } from 'src/models/clickme/clickmeVacancyPage';
import { companiesOfTheDay } from 'src/models/companiesOfTheDay';
import { complaintReasonTypes } from 'src/models/complaintReasonTypes';
import { config } from 'src/models/config';
import { contactPhones } from 'src/models/contactPhones';
import { counters } from 'src/models/counters';
import { countriesProfileVisibilityAgreement } from 'src/models/countriesProfileVisibilityAgreement';
import { CountryCode, countryId } from 'src/models/countryId';
import { crosslinks } from 'src/models/crosslinks';
import { currencies } from 'src/models/currencies';
import { CurrencyType } from 'src/models/currencies.types';
import { device } from 'src/models/device';
import { displayType } from 'src/models/displayType';
import { distances } from 'src/models/distances';
import { edoDictionary } from 'src/models/edoDictionary';
import { employerInfo } from 'src/models/employer/employerInfo';
import { employerInsiderInterview } from 'src/models/employer/employerInsiderInterview';
import { employerLastSearches } from 'src/models/employer/employerLastSearches';
import { employerLogo } from 'src/models/employer/employerLogo';
import { employerRegistrationPresentsByCountryId } from 'src/models/employer/registrationPresentsByCountryId';
import { commentsByUserId } from 'src/models/employer/resume/commentsByUser';
import { pinnedChatMessages } from 'src/models/employer/resume/pinnedChatMessages';
import { resumeComments } from 'src/models/employer/resume/resumeComments';
import { resumeSearchesWidget } from 'src/models/employer/resumeSearchesWidget';
import { employerSettingsMenu } from 'src/models/employer/settingsMenu';
import { employerDivisionChangelog } from 'src/models/employer/structure/changelog/employerDivisionChangelog';
import { serviceAdditional } from 'src/models/employer/structure/serviceAdditional';
import { vacanciesWidget } from 'src/models/employer/vacanciesWidget';
import { checkedTopics } from 'src/models/employer/vacancyresponses/checkedTopics';
import { EmployerAccountMenuNavItem } from 'src/models/employerAccountMenu';
import { employerAddresses } from 'src/models/employerAddresses';
import { employerAddressesPage } from 'src/models/employerAddressesPage';
import { employerAdvice } from 'src/models/employerAdvice';
import { employerAdvices } from 'src/models/employerAdvice/list';
import { employerAdvicesWidget } from 'src/models/employerAdvicesWidget';
import { EmployerAgreement, EmployerLinks } from 'src/models/employerAgreement.types';
import { employerAutomationStatistics } from 'src/models/employerAutomation/employerAutomationStatistics';
import { employerAutomationVacanciesAutoAction } from 'src/models/employerAutomation/employerAutomationVacanciesAutoAction';
import { employerAutomationVacanciesAutoInvite } from 'src/models/employerAutomation/employerAutomationVacanciesAutoInvite';
import { employerBanners } from 'src/models/employerBanners';
import { employerBannersWidget } from 'src/models/employerBannersWidget';
import { employerBillingInfo } from 'src/models/employerBillingInfo';
import { employerButtons } from 'src/models/employerButtons';
import { employerCarts } from 'src/models/employerCarts';
import { employerCategories } from 'src/models/employerCategories';
import { employerChangePassword } from 'src/models/employerChangePassword';
import { employerChecks } from 'src/models/employerChecks';
import { employerCompanyMenu } from 'src/models/employerCompanyMenu';
import { employerConstructor } from 'src/models/employerConstructor';
import { employerConstructorAutoAddWidget } from 'src/models/employerConstructor/autoAddWidget';
import { employerConstructorSettings } from 'src/models/employerConstructor/settings';
import { employerConsumptionSegmentType } from 'src/models/employerConsumptionSegmentType';
import { employerContactsVisibility } from 'src/models/employerContactsVisibility';
import { employerDeedOrder } from 'src/models/employerDeedOrder';
import { employerDeedOrderModal } from 'src/models/employerDeedOrderModal';
import { employerDeedsList } from 'src/models/employerDeedsList';
import { employerDepartments } from 'src/models/employerDepartments';
import { EmployerDivision, employerDivisions } from 'src/models/employerDivisions';
import { employerDocuments } from 'src/models/employerDocuments/employerDocuments';
import { employerDocumentsSettings } from 'src/models/employerDocuments/settings';
import { EmployerNavItem } from 'src/models/employerDocumentsMenu';
import { employerEdoList } from 'src/models/employerEdoList';
import { employerImplants } from 'src/models/employerImplants';
import { employerIndexPage } from 'src/models/employerIndexPage';
import { employerInsiders } from 'src/models/employerInsiders';
import { employerInvoiceActivationInfo } from 'src/models/employerInvoiceActivationInfo';
import { employerInvoiceCart } from 'src/models/employerInvoiceCart';
import { employerInvoiceClickmeInfo } from 'src/models/employerInvoiceClickmeInfo';
import { employerInvoiceFinish } from 'src/models/employerInvoiceFinish';
import { employerInvoicePaylink } from 'src/models/employerInvoicePaylink';
import { employerInvoicePayment } from 'src/models/employerInvoicePayment';
import { employerInvoicePurchase } from 'src/models/employerInvoicePurchase';
import { employerMailTemplates } from 'src/models/employerMailTemplates';
import { employerManager } from 'src/models/employerManager';
import { employerManagerPersonalData } from 'src/models/employerManagerPersonalData';
import { employerManagersList } from 'src/models/employerManagersList';
import { employerManagersName } from 'src/models/employerManagersName';
import { ManagersViewMeta, employerManagersPage } from 'src/models/employerManagersPage';
import { employerMarketSegmentType } from 'src/models/employerMarketSegmentType';
import { employerNegotiations } from 'src/models/employerNegotiations';
import { employerNegotiationLinks } from 'src/models/employerNegotiations/negotiationLinks';
import { employerNegotiationsChangeTopic } from 'src/models/employerNegotiationsChangeTopic';
import { employerPayers } from 'src/models/employerPayers';
import { employerPfpLiquidity } from 'src/models/employerPfpLiquidity';
import { employerQuotaHistory } from 'src/models/employerQuotaHistory';
import { employerQuotas } from 'src/models/employerQuotas';
import { employerRecommendedSkills } from 'src/models/employerRecommendedSkills';
import { employerSearchPublicationSettings } from 'src/models/employerSearchPublicationSettings';
import { employerSearchTabs } from 'src/models/employerSearchTabs/employerSearchTabs';
import { employerServicesWidget } from 'src/models/employerServicesWidget';
import { employerSettingsPage } from 'src/models/employerSettingsPage';
import { employerStatus } from 'src/models/employerStatus';
import { EmployerSubscriptions } from 'src/models/employerSubscriptions';
import { employerTest } from 'src/models/employerTest';
import { employerTestsConstructor } from 'src/models/employerTestsConstructor';
import { employerUserTests } from 'src/models/employerUserTests';
import { draftsPurchaseInfo } from 'src/models/employerVacancies/draftsPurchaseInfo';
import { managerVacancies } from 'src/models/employerVacancies/managerVacancies';
import { managerVacanciesFilteredGroups } from 'src/models/employerVacancies/managerVacanciesFilteredGroups';
import { suitableCounterByVacancy } from 'src/models/employerVacancies/suitableCounterByVacancy';
import { vacanciesCount } from 'src/models/employerVacancies/vacanciesCount';
import { vacanciesTabs } from 'src/models/employerVacancies/vacanciesTabs';
import { DEFAULT_FILTERS_VALUES, VacanciesFilters } from 'src/models/employerVacancies/vacancy.types';
import { vacancyHiringPlans } from 'src/models/employerVacancies/vacancyHiringPlans';
import { employerVacanciesRise } from 'src/models/employerVacanciesRise';
import { employerVacancyPromotion } from 'src/models/employerVacancyPromotion';
import { employerVacancyResponseTest } from 'src/models/employerVacancyResponseTest';
import { employerVacancySearch } from 'src/models/employerVacancySearch';
import { employerVacancyTemplateFilter } from 'src/models/employerVacancyTemplateFilter';
import { employersCompany } from 'src/models/employersCompany';
import { employersList } from 'src/models/employersList';
import { errorPage } from 'src/models/errorPage';
import { experiments } from 'src/models/experiments';
import { extraFilters } from 'src/models/extraFilters';
import { favoriteVacancies } from 'src/models/favoriteVacancies';
import { feedbackRecipients } from 'src/models/feedbackRecipients';
import { financialDocumentDeedsFilters } from 'src/models/financialDocumentDeeds/filters';
import { financialDocumentDeeds } from 'src/models/financialDocumentDeeds/financialDocumentDeeds';
import { floodlightUtm } from 'src/models/floodlightUtm';
import { folderContent } from 'src/models/folderContent';
import { giftedResumes } from 'src/models/giftedResumes';
import { globalStatistics } from 'src/models/globalStatistics';
import { insiderArticleSpecificData } from 'src/models/insiderArticleSpecificData';
import { isGeoSwitcherActive } from 'src/models/isGeoSwitcherActive';
import { jobStatistics } from 'src/models/jobStatistics';
import { kakdelaBannerReducer } from 'src/models/kakdela';
import { langs } from 'src/models/langs';
import { linkCheck } from 'src/models/linkCheck';
import { SiteLanguages, locale } from 'src/models/locale';
import { lockHistory } from 'src/models/lockHistory';
import { lowActivityVacancies } from 'src/models/lowActivityVacancies';
import { loyaltySurvey } from 'src/models/loyaltySurvey';
import { mailto } from 'src/models/mailto';
import { managerAnnouncementBanner } from 'src/models/managerAnnouncementBanner';
import { managerCluster } from 'src/models/managerCluster';
import { managerNegotiationStats } from 'src/models/managerNegotiationStats';
import { ManagerListItem } from 'src/models/managersList';
import { messengerAuthCodeForward } from 'src/models/messengerAuthCodeForward';
import { messengers } from 'src/models/messengers';
import { microFrontends } from 'src/models/microFrontends';
import { negotiationsHistory } from 'src/models/negotiationsHistory';
import { newEmployerDashboardVacancy } from 'src/models/newEmployerDashboardVacancy';
import { notes } from 'src/models/notes';
import { notifications } from 'src/models/notifications';
import { oauthCallback } from 'src/models/oauthCallback';
import { oldEmployerUpdates } from 'src/models/oldEmployer/oldEmployerUpdates';
import { pageMetaData } from 'src/models/pageMetaData';
import { Paging } from 'src/models/paging.types';
import { pfpInfo } from 'src/models/payForPerformance/pfpInfo';
import { personalManager } from 'src/models/personalManager';
import { personalManagerMenu } from 'src/models/personalManagerMenu';
import { pfpLiquidityWarning } from 'src/models/pfpLiquidityWarning';
import { platformInfo } from 'src/models/platformInfo';
import { politenessIndex } from 'src/models/politenessIndex';
import { postponedActions } from 'src/models/postponedActions';
import { priceBranding } from 'src/models/price/priceBranding';
import { priceCart } from 'src/models/price/priceCart';
import { priceData } from 'src/models/price/priceData';
import { priceDBAccess } from 'src/models/price/priceDbAccess';
import { priceEmployerBrand } from 'src/models/price/priceEmployerBrand';
import { pricePageMetaInfo } from 'src/models/price/pricePageMetaInfo';
import { pricePayForPerformance } from 'src/models/price/pricePayForPerformance';
import { priceRecommended } from 'src/models/price/priceRecommended';
import { priceZp } from 'src/models/price/priceZp';
import { PublicationProductType } from 'src/models/price/product.types';
import { priceProfRolesPublications } from 'src/models/price/profRolesPublications';
import { priceRegionalPublications } from 'src/models/price/regionalPublications';
import { priceAddContacts } from 'src/models/priceAddContacts';
import { priceClickmeProducts } from 'src/models/priceClickmeProducts';
import { printVersion } from 'src/models/printVersion';
import { probationCatalog } from 'src/models/probationCatalog';
import { ProfessionalAreaItem } from 'src/models/professionalAreaTree';
import { professionalRoleCategoryCollection } from 'src/models/professionalRoleCategoryCollection';
import { ProfessionalRoleTree } from 'src/models/professionalRoleTree';
import { professionsList } from 'src/models/professionsList';
import { prolongateInfo } from 'src/models/prolongateInfo';
import { promotionUseSearchTop } from 'src/models/promotionUseSearchTop';
import { ratingTags } from 'src/models/ratingTags';
import { readOnlyBanner } from 'src/models/readOnlyBanner';
import { recommendedVacancies } from 'src/models/recommendedVacancies';
import { relatedVacancies } from 'src/models/relatedVacancies';
import { relatedVacanciesForEmployer } from 'src/models/relatedVacanciesForEmployer';
import { relocationWarning } from 'src/models/relocationWarning';
import { restorePasswordConditions } from 'src/models/restorePasswordConditions';
import { resume } from 'src/models/resume';
import { ResumeId } from 'src/models/resume/resume.types';
import { ResumeAccessType } from 'src/models/resume/resumeAccess.types';
import { EducationLevel } from 'src/models/resume/resumeCommon.types';
import { VerifiedSkillsWithReportsFlag } from 'src/models/resume/resumeVerifiedSkills.types';
import { ResumeCountriesVisibilitySettings } from 'src/models/resumeCountriesVisibility';
import { resumeFolders } from 'src/models/resumeFolders';
import { resumeFormAttributes } from 'src/models/resumeForm/attributes';
import { resumeFormConditions } from 'src/models/resumeForm/conditions';
import { resumeFormDefaults } from 'src/models/resumeForm/defaults';
import { resumeFormFields } from 'src/models/resumeForm/fields';
import { resumeFormLanguages } from 'src/models/resumeForm/languages';
import { resumeFormModerationNotes } from 'src/models/resumeForm/moderationNotes';
import { resumeFormVacancy } from 'src/models/resumeForm/vacancy';
import { Language, LanguageLevel } from 'src/models/resumeLanguage';
import { resumeLimits } from 'src/models/resumeLimits';
import { resumeSavedSearch } from 'src/models/resumeSavedSearch';
import { resumeSearchContext } from 'src/models/resumeSearchContext';
import { resumeSearchQuery } from 'src/models/resumeSearchQuery';
import { resumeSearchSeenAnalytics } from 'src/models/resumeSearchSeenAnalytics';
import { resumeShareButtons } from 'src/models/resumeShareButtons';
import { resumeViewHistory } from 'src/models/resumeViewHistory';
import { resumeVisibilityEmployerBlacklist } from 'src/models/resumeVisibility/resumeVisibilityEmployerBlacklist';
import { resumeVisibilityEmployerWhitelist } from 'src/models/resumeVisibility/resumeVisibilityEmployerWhitelist';
import { resumeVisibilityEmployerSearchResultsBlacklist } from 'src/models/resumeVisibility/resumeVisibilitySearchResultsBlacklist';
import { resumeVisibilityEmployerSearchResultsWhitelist } from 'src/models/resumeVisibility/resumeVisibilitySearchResultsWhitelist';
import { resumeZp } from 'src/models/resumeZp';
import { resumesComplaint } from 'src/models/resumesComplaint';
import { resumesViewSettings } from 'src/models/resumesViewSettings';
import { riseExtra } from 'src/models/riseExtra';
import { rsvUserCompetencies } from 'src/models/rsvUserCompetencies';
import { salaryStat } from 'src/models/salaryStat';
import { scheduledInterviews } from 'src/models/scheduledInterviews';
import { advancedSearch } from 'src/models/search/common/advanced';
import { searchClusters } from 'src/models/search/common/clusters';
import { searchDraftClusters } from 'src/models/search/common/clusters/draft';
import { searchClustersOrder } from 'src/models/search/common/clustersOrder';
import { searchDraftClustersOrder } from 'src/models/search/common/clustersOrder/draft';
import { searchClustersTip } from 'src/models/search/common/clustersTip';
import { searchDraft } from 'src/models/search/common/draft';
import { searchGeoDrawer } from 'src/models/search/common/geoDrawer';
import { searchSettings } from 'src/models/search/common/settings';
import { resumeSearchDictionaries } from 'src/models/search/resume/dictionaries';
import { searchQueries } from 'src/models/search/resume/queries';
import { resumeSearchResult } from 'src/models/search/resume/result';
import { advancedVacancySearch } from 'src/models/search/vacancy/advanced';
import { vacancySearchDictionaries } from 'src/models/search/vacancy/dictionaries';
import { vacancySearchResult } from 'src/models/search/vacancy/result';
import { novaSorts } from 'src/models/search/vacancy/sorts';
import { searchCatalog } from 'src/models/searchCatalog';
import { searchCatalogList } from 'src/models/searchCatalogList';
import { searchExplanation } from 'src/models/searchExplanation';
import { searchMapResponse } from 'src/models/searchMapResponse';
import { servicesCount } from 'src/models/servicesCount';
import { session } from 'src/models/session';
import { smartScript } from 'src/models/smartScript';
import { snippetAccessResume } from 'src/models/snippetAccessResume/snippetAccessResume';
import { socialNetworksLinks } from 'src/models/socialNetworksLinks';
import { sortingFilters } from 'src/models/sortingFilters';
import { specialProjectReducer } from 'src/models/specialProject';
import { stayInTouch } from 'src/models/stayInTouch';
import { subscriptionInformer } from 'src/models/subscriptionInformer';
import { suitableVacancies } from 'src/models/suitableVacancies';
import { footerReducer, NavItem, supernovaNaviMenu } from 'src/models/supernovaNaviMenu';
import { supernovaSearchName } from 'src/models/supernovaSearchName';
import { supernovaUserType } from 'src/models/supernovaUserType';
import { supportChat } from 'src/models/supportChat';
import { testsLibraries } from 'src/models/tests_libraries';
import { testCategories } from 'src/models/tests_libraries/categories';
import { topLevelSite } from 'src/models/topLevelSite';
import { topPromoBanner } from 'src/models/topPromoBanner';
import { topicsCallHistory } from 'src/models/topicsCallHistory';
import { trl } from 'src/models/trl';
import { unpremoderatedVacancyList } from 'src/models/unpremoderatedVacancyList';
import { userLabelsForVacancies } from 'src/models/userLabelsForVacancies/userLabels';
import { userNotifications } from 'src/models/userNotifications';
import { userSearchPointsByResumeHash } from 'src/models/userSearchPointsByResumeHash';
import { userStats } from 'src/models/userStats';
import { userTargeting } from 'src/models/userTargeting';
import { UserType, userType } from 'src/models/userType';
import { vacanciesGroupsByRegion } from 'src/models/vacanciesGroupsByRegion';
import { vacanciesOfTheDay } from 'src/models/vacanciesOfTheDay';
import {
    DEFAULT_VACANCY_COMPENSATION_FIELDS_DICTIONARY,
    VacancyCompensationFieldsDictionary,
} from 'src/models/vacancy/compensationFieldsDictionary.types';
import {
    DEFAULT_VACANCY_EMPLOYMENT_FIELDS_DICTIONARY,
    VacancyEmploymentFieldsDictionary,
} from 'src/models/vacancy/employmentFieldsDictionary.types';
import { HiringManagerVacancyPermission } from 'src/models/vacancy/permissions.types';
import { vacancyTemplateForEdit } from 'src/models/vacancy/templateForEdit';
import { vacancyTemplates } from 'src/models/vacancy/templates';
import { VacancyAdvice } from 'src/models/vacancy/vacancyAdvices.types';
import { vacancyAdviceModalData } from 'src/models/vacancyAdviceModalData';
import { vacancyAnalyticsConversion } from 'src/models/vacancyAnalyticsConversion';
import { vacancyAutoActions } from 'src/models/vacancyAutoActions';
// tempexp_PORTFOLIO-40783_next_line
import { vacancyBenefits } from 'src/models/vacancyBenefits';
import { vacancyCallsPage } from 'src/models/vacancyCallsPage';
import { vacancyChatInfo } from 'src/models/vacancyChatInfo';
import { vacancyConstructorSettings } from 'src/models/vacancyConstructorSettings';
import { vacancyConversionStatistics } from 'src/models/vacancyConversionStatistics';
import { vacancyCreate } from 'src/models/vacancyCreate';
import { VacancyCreateTemplate } from 'src/models/vacancyCreate/vacancyCreate.types';
import { vacancyFeatures } from 'src/models/vacancyFeatures';
import { vacancyFull } from 'src/models/vacancyFull';
import { vacancyFunnel } from 'src/models/vacancyFunnel';
import { vacancyFunnelTemplates } from 'src/models/vacancyFunnelTemplates';
import { vacancyInternalInfo } from 'src/models/vacancyInternalInfo';
import { vacancyMessages } from 'src/models/vacancyMessages';
import { vacancyMetro } from 'src/models/vacancyMetro';
import { vacancyOnlineUsersCounters } from 'src/models/vacancyOnlineUsersCounters';
import { vacancyOutcomingCallsPage } from 'src/models/vacancyOutcomingCallsPage';
import { vacancyPopularCities } from 'src/models/vacancyPopularCities';
import { vacancyResponseFlags } from 'src/models/vacancyResponseFlags';
import { vacancyResponsePopup } from 'src/models/vacancyResponsePopup';
import { vacancyResponseQuestions } from 'src/models/vacancyResponseQuestions';
import { vacancyResponseSummary } from 'src/models/vacancyResponseSummary';
import { autoAction } from 'src/models/vacancyResponsesAutomation/autoAction';
import { AutomationStatistics } from 'src/models/vacancyResponsesAutomation/automationStatistics';
import { vacancyResponsesFilters } from 'src/models/vacancyResponsesFilters';
import { vacancySavedSearches } from 'src/models/vacancySavedSearch';
import { vacancySearchSeenAnalytics } from 'src/models/vacancySearchSeenAnalytics';
import { vacancySearchSnippet } from 'src/models/vacancySearchSnippet';
import { vacancyTests } from 'src/models/vacancyTests';
import { vacancyToResumeSkillMatch } from 'src/models/vacancyToResumeSkillMatch';
import { vacancyView } from 'src/models/vacancyView';
import { vacancyViewTab } from 'src/models/vacancyViewTab';
import { vacancyWizard } from 'src/models/vacancyWizard';
import { vacation } from 'src/models/vacation';
import { verificationActs } from 'src/models/verificationActs/verificationActs';
import { VerificationReport } from 'src/models/verificationReports';
import { viewDuration } from 'src/models/viewDuration';
import { vrSignupLogin } from 'src/models/vrSignupLogin';
import { vrVacancies } from 'src/models/vrVacancies';
import { webcall } from 'src/models/webcall';
import { workInOtherCity } from 'src/models/workInOtherCities';
import { SearchTypeGenitive, DistrictsMap } from 'src/types/search/common';
import { ClusterKey } from 'src/types/search/common/clusters';
import { MapCriteria } from 'src/types/search/vacancy/criteria';
import { ResumeAuditData } from 'src/utils/constants/applicantServices';

const reducers = {
    aiAssistantHR,
    activeEmployerVacancyCount: autoGeneratedReducer<number>(0),
    auctionData,
    auctionHhtmSourceLabel,
    auctionConfirmationModal,
    authPhone,
    autoAction,
    ...autoInvite,
    employerAutomationStatistics,
    employerAutomationVacanciesAutoAction,
    employerAutomationVacanciesAutoInvite,
    autoActionPromo: autoGeneratedReducer<boolean>(null),
    authNewEmployerAreaIdsToRedirect: autoGeneratedReducer<string[]>([]),
    authNewEmployerCategories,
    authNewEmployerFields,
    authNewEmployerInitialValues,
    authNewEmployerPhoneMask: autoGeneratedReducer<string>(null),
    autoUpdateAvailableForAnyResume: autoGeneratedReducer<boolean>(false),
    activeResumeAccessType: autoGeneratedReducer<ResumeAccessType>(null),
    accountTemporarilyLocked,
    accountPhoneVerification,
    applicantPortraitCategories,
    applicantSignup,
    applicantVacancyResponseStatuses,
    applicantResumes,
    applicantResponseStreaks,
    ...applicantServicesPayment,
    applicantSuitableVacancyByResume: autoGeneratedReducer<ApplicantSuitableVacancyByResumeHash>({}),
    account: autoGeneratedReducer<Account>(null),
    accountConnect,
    accountConnectOAuth: autoGeneratedReducer<{ id?: string; url?: string }>({}),
    accountConnectResponse,
    actualAccount: autoGeneratedReducer<Account>(null),
    accountHistoryServices,
    accountHistoryReplenishments,
    accountHistoryExpenditures,
    articleHHATags,
    articlePage,
    articleEmployerInfo,
    articleRating,
    articleViews,
    artifactRules,
    archiveSearch: autoGeneratedReducer<boolean>(false),
    ...archivedVacancies,
    hhidAccount: autoGeneratedReducer<Account>(null),
    accountDelete,
    adsSearchParams,
    adsVacancyParams,
    alreadyRegisteredEmployer: autoGeneratedReducer<{ id: string; name: string }>(null),
    anonymousUserType: autoGeneratedReducer<UserType.Applicant | UserType.Employer>(null),
    anonymousAgreementAccepted: autoGeneratedReducer(false),
    advancedSearch,
    advancedVacancySearch,
    agencies,
    agenciesList,
    anonymousEmployer,
    allowedSMSCountries: autoGeneratedReducer<CountryCode>(null),
    allowedCountriesForSmsRegistration,
    isVacancyBrandedSnippetAllowed: autoGeneratedReducer<boolean>(false),
    abortPageContent: autoGeneratedReducer<boolean>(false),
    abortLegacyVacancyListOnWebView: autoGeneratedReducer<boolean>(false),
    applicantActivity,
    applicantActivityEvents,
    applicantApplications: applications,
    applicantBlacklistVacancy,
    applicantBlacklistEmployers,
    applicantGallery,
    applicantSkillsVerificationMethod: autoGeneratedReducer<ApplicantSkillsVerificationMethod>(null),
    applicantSkillsVerificationMethods: autoGeneratedReducer<ApplicantSkillsVerificationMethods>([]),
    applicantSkillsVerificationExpiring: autoGeneratedReducer<ApplicantSkillsVerificationMethods>([]),
    applicantSkillsVerificationResult,
    applicantSkillsVerificationError,
    applicantEmployerManagersActivity,
    applicantNews: autoGeneratedReducer<ArticleItemWithImage[]>([]),
    applicantArticles: autoGeneratedReducer<ArticleItemWithImage[]>([]),
    applicantLatestResume: autoGeneratedReducer<{
        '@found': boolean;
        resumeHash: string;
        resumeId: number;
        title: string;
    }>(null),
    applicantFaq: autoGeneratedReducer<ArticleItemWithTopic[]>([]),
    applicantShowProfileOnboarding: autoGeneratedReducer<boolean>(false),
    addressesSuggestRemoteMode: autoGeneratedReducer(false),
    analyticsParams: autoGeneratedReducer<{
        hhtmFrom: string;
        hhtmSource: string;
        screenType?: string;
        hhtmFromLabel?: string;
    }>({
        hhtmFrom: '',
        hhtmSource: '',
        hhtmFromLabel: undefined,
        screenType: undefined,
    }),
    isAnonymousMagritteExp: autoGeneratedReducer<boolean>(false),
    isAnonymousMagritteExpB: autoGeneratedReducer<boolean>(false),
    isAnonymousMagritteExpC: autoGeneratedReducer<boolean>(false),
    isAnonymousMagritteExpD: autoGeneratedReducer<boolean>(false),
    isPresenceGoalsExpB: autoGeneratedReducer<boolean>(false),
    isPresenceGoalsExpC: autoGeneratedReducer<boolean>(false),
    applicantInfo: autoGeneratedReducer({ total: 0, finished: 0, visible: 0, searchable: 0 }),
    applicantNegotiations,
    applicantNegotiationsCounters,
    applicantNegotiationsActionsData,
    applicantPaymentServices,
    applicantResumesStatistics,
    applicantSettingsMenu,
    applicantSubscriptions,
    applicantUseful: autoGeneratedReducer<ArticleItemWithTopic[]>([]),
    applicantUserStatuses,
    applicantUserStatusesTrigger: autoGeneratedReducer<string>(null),
    applyAgreement,
    appPromoPageBackurl,
    autologin: autoGeneratedReducer<{ reloginUrl?: string; sameUserUrl?: string }>({ reloginUrl: '', sameUserUrl: '' }),
    jobSearchStatusPostChangeModal,
    applicantPossibleJobOffers,
    authUrl,
    automationStatistics: autoGeneratedReducer<AutomationStatistics>({}),
    autoprolongation: autoGeneratedReducer<{
        isPublicationRequired?: boolean;
    }>({}),
    availableVacancyActions,
    brandedVacancyTemplates,
    brandSnippetEditor,
    brandSnippets,
    employerSubscriptions: autoGeneratedReducer<EmployerSubscriptions>({
        initialValues: { emailCheckboxes: [], pushCheckboxes: [], smsCheckboxes: [] },
        formItems: { smsCheckboxes: [], emailCheckboxes: [], pushCheckboxes: [] },
        employerId: '',
    }),
    emailVerification: autoGeneratedReducer({ email: '', needVerification: false, mailURL: '' }),
    employerTests: autoGeneratedReducer<{ id: string; name: string }[]>([]),
    employerRegistrationPresentsByCountryId,
    applicantResumesIdentifiers: autoGeneratedReducer<{ '@id': number }[]>([]),
    areaTree: autoGeneratedReducer<ModelData<AdditionalDefault>[]>([]),
    assessments,
    loginForm,
    backofficeCanEditTemplate: autoGeneratedReducer(true),
    backUrl: autoGeneratedReducer<string>(null),
    banners,
    bannersBatchUrl: autoGeneratedReducer<string>(null),
    blogPage,
    blogViews,
    brandingPromotion,
    brandSnippetsData,
    resumeExtraBannerLink: autoGeneratedReducer(''),
    cancelFetchBanners: autoGeneratedReducer<boolean>(false),
    chameleonWidePage: autoGeneratedReducer<boolean>(false),
    candidates,
    candidatesList,
    canBuyBrandSnippet: autoGeneratedReducer(false),
    vacancyResponsesFilters,
    canvacSimilarVacancies,
    canvacVacancySalaryData,
    applicantEmployerPoliteness,
    captchaAccountState: autoGeneratedReducer({ state: '', backurl: '', failurl: '' }),
    careerMarathon: autoGeneratedReducer({ email: '', emailVerified: false }),
    catalogLinks: autoGeneratedReducer<{ path: string; title: string }[]>([]),
    chatBot,
    chatik,
    chatikIntegration: autoGeneratedReducer(false),
    chatikCounters: autoGeneratedReducer<CountersValue>({ unreadCount: null, unreadSupportCount: null }),
    chatikFloatActivator,
    checkedTopics,
    clickmeAutoCampaignPromotionEnabled: autoGeneratedReducer(false),
    clickmeInfo: autoGeneratedReducer<{ available: boolean; defaultValue: boolean }>(null),
    clickmeAutoCampaignPromotion,
    clickmeProducts,
    clickmeVacancyPage,
    commentsByUserId,
    companiesOfTheDay,
    config,
    counters,
    countryPhoneCode: autoGeneratedReducer(''),
    untrustedEmployer: autoGeneratedReducer(false),
    contactPhones,
    companiesOfTheDayTrls: autoGeneratedReducer({ area: '' }),
    currencies,
    complaintReasonTypes,
    chatWritePossibilities: autoGeneratedReducer<{ trlKey: string; value: ChatWritePossibility }[]>([]),
    criteriaCurrencyCode: autoGeneratedReducer<CurrencyType>(CurrencyType.RUR),
    crosslinks,
    currentManagerId: autoGeneratedReducer(''),
    countryId,
    countriesProfileVisibilityAgreement,
    defaultCountryCompanySearchId: autoGeneratedReducer<string>(null),
    savedAreaIds: autoGeneratedReducer<string[]>([]),
    displayType,
    distances,
    domainRegionTrl: autoGeneratedReducer<string>(''),
    domainRegionTrlNom: autoGeneratedReducer<string>(''),
    employerAddresses,
    draftsPurchaseInfo,
    employerApplications: applications,
    employerAddressesCount: autoGeneratedReducer<number>(null),
    employerAddressesPage,
    employerAgreementsSellerIds: autoGeneratedReducer<string[]>([]),
    employerAgreements: autoGeneratedReducer<EmployerAgreement[]>([]),
    employerLinks: autoGeneratedReducer<EmployerLinks>({ publicAgreementFileUrlPdf: '' }),
    employerArticles: autoGeneratedReducer<ArticleItemWithImage[]>([]),
    employerBillingInfo,
    employerCarts,
    employerChecks,
    employerAdvice,
    employerAdvices,
    // tempexp_38140_next_line
    employerBanners,
    // tempexp_37861_start
    hideResponseContactBeforeBannerEnabled: autoGeneratedReducer<boolean>(false),
    hideResponseContactAfterBannerEnabled: autoGeneratedReducer<boolean>(false),
    // tempexp_37861_end
    employerButtons,
    employerNegotiationsChangeTopic,
    employerQuotaHistory,
    employerCompanyMenu,
    employerConstructorWebViewMode: autoGeneratedReducer(false),
    employerConstructorAutoAddWidget,
    employerConstructorSettings,
    employerConstructor,
    employerContactsVisibility,
    employerCategories,
    employerChangePassword,
    employerDocuments,
    employerPageIsViewMode: autoGeneratedReducer<boolean>(false),
    employerCurrentDivision: autoGeneratedReducer<EmployerDivision>(null),
    employerDocumentsSettings,
    employerEdoList,
    employerPayers,
    employerDocumentsMenu: autoGeneratedReducer<EmployerNavItem[]>([]),
    employerAccountMenu: autoGeneratedReducer<EmployerAccountMenuNavItem[]>([]),
    employerAccountMenuCounters: autoGeneratedReducer<Record<string, number>>({}),
    employerDepartments,
    employerDivisions,
    employerFaq: autoGeneratedReducer<ArticleItemWithTopic[]>([]),
    employerHasDivisions: autoGeneratedReducer(false),
    employerId: autoGeneratedReducer<string>(''),
    employerImplants,
    employerName: autoGeneratedReducer<string>(''),
    employerIndexPage,
    employerLogo,
    employerPfpLiquidity,
    edoDictionary,
    employerInfo,
    employerInsiders,
    vacancyAutoActions,
    employerInvoiceFinish,
    employerInvoicePurchase,
    employerInvoicePaylink,
    employerInvoicePayment,
    employerInvoiceCart,
    employerInvoiceActivationInfo,
    employerInvoiceClickmeInfo,
    employerSearchPublicationSettings,
    employerLastSearches,
    employersListQuery: autoGeneratedReducer<string>(null),
    employerSettingsVacancyPublication: autoGeneratedReducer<{ htmlEditor?: string; templateVacancy?: string }>({}),
    employerInsiderInterview,
    employerManager,
    employerManagerPersonalData,
    employerNoAdviceArticles: autoGeneratedReducer<{ id: string; title: string }[]>([]),
    employerManagersPage,
    employerManagersName,
    employerManagersList,
    employerManagersCount: autoGeneratedReducer<number>(null),
    employerMarketSegmentType,
    employerConsumptionSegmentType,
    employersCompany,
    employersList,
    employerNews: autoGeneratedReducer<ArticleItemWithImage[]>([]),
    employerExperiences: autoGeneratedReducer<{
        experience: {
            code: WorkExperience;
            searchCode: string;
            trKey: string;
            idInt: number;
            id: string;
        }[];
    }>({ experience: [] }),
    employerReviewsApplicantProfileBadge: autoGeneratedReducer(false),
    employerReviewsIsOpenEmployer: autoGeneratedReducer(false),
    employerSearchSettings: autoGeneratedReducer<{ openInNewWindow?: boolean }>({}),
    employerSettingsMenu,
    employerNegotiations,
    employerNegotiationLinks,
    employerSettingsPage,
    employerStatus,
    employerUserTests,
    employerTest,
    employerTestsConstructor,
    employerMailTemplates,
    employerViewSelectedAreas: autoGeneratedReducer<string[]>([]),
    employerVacanciesAuctionVacancyId: autoGeneratedReducer<string>(null),
    employerVacanciesRise,
    // tempexp_37869_next_line
    riseExtra,
    employerVacancyPromotion,
    employerRegistrationTimeInDelta: autoGeneratedReducer(false),
    employerQuotas,
    enableSimilarSavedSearch: autoGeneratedReducer(false),
    enableVacancySnippets: autoGeneratedReducer<boolean>(true),
    experiments,
    extraFilters,
    educationLevels: autoGeneratedReducer<{ value: EducationLevel; text: string }[]>([]),
    errorCode: autoGeneratedReducer<number>(null),
    favoriteVacancies,
    fastVacanciesSubscriptionSettings: autoGeneratedReducer<{ sendEmail?: boolean; sendPush?: boolean }>({}),
    favoriteVacanciesCount: autoGeneratedReducer<number>(null),
    features: autoGeneratedReducer<Record<string, unknown>>({}),
    feedbackRecipients,
    filteredVacancyResponsesCount: autoGeneratedReducer<number>(null),
    floodlightGtagEnabled: autoGeneratedReducer<boolean>(false),
    floodlightUtm,
    financialDocumentDeeds,
    financialDocumentDeedsFilters,
    financialDocumentDeedsPaging: autoGeneratedReducer<Paging>(null),
    financialDocumentDeedsTotalRows: autoGeneratedReducer<number>(null),
    fetchingData: autoGeneratedReducer<boolean>(false),
    folderContent,
    footer: footerReducer,
    forbidden: autoGeneratedReducer(false),
    employerVacancySearch,
    employerVacancyTemplateFilter,
    errorPage,
    globalInvitations: autoGeneratedReducer<number>(null),
    getPageHtmlEnabled: autoGeneratedReducer(false),
    globalStatistics,
    hasEditSidebarButton: autoGeneratedReducer(false),
    employerLogoEditSettings: autoGeneratedReducer<{
        maxSizeBytes: number;
        allowedMimeTypes: string[];
        maxWidth: number;
        maxHeight: number;
        minWidth: number;
        minHeight: number;
    }>(null),
    hasForceReloadDreamJob: autoGeneratedReducer(false),
    hasListOfVacanciesByRoles: autoGeneratedReducer(false),
    hasVacancyResponsesSummaryService: autoGeneratedReducer(false),
    isNewBrandingPricePageEnabled: autoGeneratedReducer(false),
    hasVideoInterview: autoGeneratedReducer(false),
    hasChameleon: autoGeneratedReducer(false),
    headerMenu: supernovaNaviMenu,
    hideFooterServices: autoGeneratedReducer(false),
    hhid: autoGeneratedReducer<string>(null),
    industriesTree: autoGeneratedReducer<ModelData[]>([]),
    infoTip: autoGeneratedReducer<{ name?: string; context?: Record<string, unknown> }>({}),
    insiderArticleSpecificData,
    isAvailableHhPro: autoGeneratedReducer<boolean>(false),
    isBot: autoGeneratedReducer<boolean>(false),
    isClickmeAnalyticsEnabled: autoGeneratedReducer<boolean>(false),
    isExpNewEmploymentFilters: autoGeneratedReducer<boolean>(false),
    isFunnelGroupsExp: autoGeneratedReducer<boolean>(false),
    isLightPage: autoGeneratedReducer(false),
    isVacancyMapLoadingCompleted: autoGeneratedReducer(false),
    isMagritteEmployerPageHeaderExp: autoGeneratedReducer(false),
    isNewEmployer: autoGeneratedReducer(false),
    // tempexp_39120_next-line
    isNewOrReactivatedEmployer: autoGeneratedReducer(false),
    isNewVacancy: autoGeneratedReducer(false),
    isResumeCardImprovedExp: autoGeneratedReducer<boolean>(false),
    // tempexp_PORTFOLIO-40023_nextline
    isHhproSubsriptionModalExp: autoGeneratedReducer(false),
    isSearchCatalog: autoGeneratedReducer<boolean>(false),
    isSetkaBannerShowOnSearchVacancy: autoGeneratedReducer<boolean>(false),
    isAdjustDetectedRegionResumeExpEnabled: autoGeneratedReducer(false),
    isWebView: autoGeneratedReducer(false),
    hideLayout: autoGeneratedReducer(false),
    domainAreaTitle: autoGeneratedReducer(''),
    isIFrame: autoGeneratedReducer(false),
    isMultiAccount: autoGeneratedReducer(false),
    multiAccountCreationPayload: autoGeneratedReducer<string>(null),
    isEmployerRegistrationFromDraft: autoGeneratedReducer(false),
    isTinkoffBusinessError: autoGeneratedReducer(false),
    isPfpVacancy: autoGeneratedReducer<boolean>(null),
    jobStatistics,
    kakdelaBanner: kakdelaBannerReducer,
    langs,
    languages: autoGeneratedReducer<{ language: Language[] }>({ language: [] }),
    languageLevels: autoGeneratedReducer<{ level: LanguageLevel[] }>({ level: [] }),
    lastVacancyResponseInfo: autoGeneratedReducer<{ letter?: string; resumeHash?: string }>({}),
    lastQuestionResponse: autoGeneratedReducer(''),
    lastUpdatedSearchQueryText: autoGeneratedReducer(''),
    linkCheck,
    loyaltySurvey,
    locale,
    lockHistory,
    logos: autoGeneratedReducer({ logoDomain: '', smallLogoDomain: '' }),
    loginError: autoGeneratedReducer<{ code: string; trl?: string }>(null),
    loginTileType: autoGeneratedReducer<UserType.Applicant | UserType.Employer>(null),
    lowActivityVacancies,
    mainContentVisible: autoGeneratedReducer(true),
    managerAnnouncementBanner,
    managersList: autoGeneratedReducer<ManagerListItem[]>([]),
    managersListLoaded: autoGeneratedReducer(false),
    moderators: autoGeneratedReducer<{ id: number; fullName: string; self: boolean }[]>([]),
    mapVacancySearchQuery: autoGeneratedReducer<Partial<MapCriteria>>({}),
    managerNegotiationStats,
    managerCluster,
    managerVacanciesActiveFilteredGroupId: autoGeneratedReducer<string>(null),
    managerVacanciesFilteredGroups,
    mapHasGeoClusters: autoGeneratedReducer(false),
    mailto,
    messengerAuthCodeForward,
    messengers,
    metaRole: autoGeneratedReducer<string>(null),
    metroCatalog: autoGeneratedReducer({
        currentStation: '',
        currentStationTranslation: '',
        cityMetro: '',
        currentLine: '',
        currentLineTranslation: '',
    }),
    managerActiveCount: autoGeneratedReducer<{ count: number }>(null),
    metroCities: autoGeneratedReducer<string[]>([]),
    citiesDistricts: autoGeneratedReducer<DistrictsMap>({}),
    microFrontends,
    mobileAppPromotionVisible: autoGeneratedReducer(false),
    newEmployerDashboardVacancy,
    newTargetEmployerResumes: autoGeneratedReducer<string[]>([]),
    notifications,
    noMenu: autoGeneratedReducer(false),
    numOfOpenVacancies: autoGeneratedReducer<number>(null),
    negotiationsHistory,
    novaSorts,
    oauthInfo: autoGeneratedReducer<{
        name?: string;
        redirectUri?: string;
        isAccountSelection?: boolean;
        state?: string;
        requestRedirectUri?: string;
        oauthDenyAccessUrl?: string;
        accountChangeUrl?: string;
        errorReason?: string;
    }>({}),
    oauthCallback,
    oldEmployerUpdates,
    overlayMenu: autoGeneratedReducer(false),
    pageMetaData,
    permissions: autoGeneratedReducer<string[]>([]),
    personalManager,
    personalManagerMenu,
    pfpInfo,
    showPfpBalanceWarning: autoGeneratedReducer(false),
    postponedActions,
    employerSearchTabs,
    previousEmployerTargets: autoGeneratedReducer<
        {
            activationTime: number;
            id: number;
            smsCount: number;
        }[]
    >([]),
    priceCart,
    priceData,
    priceDBAccess,
    priceEmployerBrand,
    priceNewCartEnabled: autoGeneratedReducer(false),
    pricePageMetaInfo,
    pricePayForPerformance,
    priceRecommended,
    priceProfRolesPublications,
    priceRegionalPublications,
    priceClickmeProducts,
    priceAddContacts,
    priceZp,
    printVersion,
    // TODO add types PORTFOLIO-16270
    priceBranding,
    probationCatalog,
    rsvUserCompetencies,
    rsvCompetencyData,
    partTimeTypes: autoGeneratedReducer<{
        workingDays: { code: WorkingDay }[];
        workingTimeIntervals: { code: WorkingTimeInterval }[];
        workingTimeModes: { code: WorkingTimeMode }[];
    }>(null),
    profareaTree: autoGeneratedReducer<ProfessionalAreaItem[]>([]),
    professionsList,
    professionalRoleCategoryCollection,
    professionalRolesFromSearch: autoGeneratedReducer<string[]>([]),
    professionalRoleTree: autoGeneratedReducer<ProfessionalRoleTree>({
        items: [],
        roleNotFoundSubstituteIds: [],
    }),
    promotionUseSearchTop,
    partTimeVacancies: autoGeneratedReducer({ areaTrl: '' }),
    publishedForResponseResumeHash: autoGeneratedReducer<string>(null),
    ratingTags,
    runActionArgument: autoGeneratedReducer<string>(null),
    isVacancyAdvicePlaceAllowed: autoGeneratedReducer<boolean>(false),
    readOnlyBanner,
    recommendedPublicationPrice: autoGeneratedReducer<PublicationProductType>(null),
    registeredEmployerCompanyName: autoGeneratedReducer<string>(''),
    registeredEmployerEmail: autoGeneratedReducer<string>(''),
    redirectCatalogPath: autoGeneratedReducer<string>(null),
    remoteWorkCounter: autoGeneratedReducer<{ areaId?: number; count?: number }>({}),
    renderRestriction: autoGeneratedReducer<{
        breakpoint?: BlokoBreakpoint;
        force: boolean;
        magritteBreakpoint?: MagritteBreakpoint;
        renderRestriction: false;
    }>({
        force: false,
        renderRestriction: false,
    }),
    request: autoGeneratedReducer<{
        protocol: string;
        host: string;
        origin: string;
        luxPageName: string;
        url: string;
        id: string;
    }>({
        protocol: 'https',
        host: 'hh.ru',
        origin: 'https://hh.ru',
        luxPageName: '',
        url: '',
        id: '',
    }),
    regionClarification: autoGeneratedReducer<{ host: string; translations: { area: string } }>(null),
    resume,
    resumeAdditionalData,
    resumeEditor,
    applicantResume,
    applicantResumeArtifacts,
    applicantSkillsLevelsEditor,
    resumeSearchContext,
    resumeSearchContextVacancySelectorVisible: autoGeneratedReducer(false),
    resumeSearchSeenAnalytics,
    registrationTimeMillis: autoGeneratedReducer<number>(null),
    resumeAnalyticLabel: autoGeneratedReducer<string>(null),
    resumeCountriesVisibility: autoGeneratedReducer<ResumeCountriesVisibilitySettings>({
        visibleForCommonCountries: false,
        visibleForUzbekistan: false,
    }),
    resumeIdsProfTestAttached: autoGeneratedReducer<number[]>([]),
    resumeViewHistory,
    resumeVisibilityEmployerBlacklist,
    resumeVisibilityEmployerWhitelist,
    resumeVisibilityEmployerSearchResultsBlacklist,
    resumeVisibilityEmployerSearchResultsWhitelist,
    resumeBuilderField: autoGeneratedReducer<string>(null),
    resumeCommentsLimit: autoGeneratedReducer<number>(null),
    resumesViewSettings,
    resumesComplaint,
    resumesForUpdate: autoGeneratedReducer<string[]>(null),
    resumeFolders,
    resumeFormError: autoGeneratedReducer<string>(null),
    resumeFormHash: autoGeneratedReducer(''),
    resumeFormAttributes,
    resumeFormConditions,
    resumeFormDefaults,
    resumeFormFields,
    resumeTitle: autoGeneratedReducer<string>(null),
    resumeComments,
    resumeFormModerationNotes,
    resumeFormLanguages,
    resumeFormVacancy,
    resumeLimits,
    resumeSearchDictionaries,
    resumeSearchResult,
    resumeSearchQuery,
    resumeShareButtons,
    resumeLanguages: autoGeneratedReducer<SiteLanguages[]>([]),
    resumeExportTypes: resumeExportTypesReducer,
    resumeServicePrice: autoGeneratedReducer<{
        amount: number;
        currency: CurrencyType;
    }>(null),
    resumeSavedSearch,
    resumeSearchesWidget,
    resumeVerifiedSkills: autoGeneratedReducer<Record<ResumeId, VerifiedSkillsWithReportsFlag>>({}),
    resumeViewBackoffice,
    resumeZp,
    relocationWarning,
    relatedVacancies,
    relatedVacanciesType: autoGeneratedReducer<'similar' | 'suitable'>(null),
    relatedVacanciesEmployerId: autoGeneratedReducer<number>(null),
    recommendedVacancies,
    relatedVacanciesForEmployer,
    restorePasswordConditions,
    canShowWantMoreActionBar: autoGeneratedReducer(false),
    resumeAuditRecommendation: autoGeneratedReducer<ResumeAuditData>(null),
    salaryStat,
    searchCatalog,
    searchCatalogList,
    searchCatalogRedirect: autoGeneratedReducer<boolean>(true),
    searchCatalogType: autoGeneratedReducer<SearchTypeGenitive>(null),
    searchMobileControls: autoGeneratedReducer<boolean>(false),
    searchLoading: autoGeneratedReducer<boolean>(false),
    savedVacancySearch: autoGeneratedReducer<{ totalPages: number; totalItems: number; newVacancies: number }>(null),
    searchPreference: autoGeneratedReducer({ isShown: false }),
    searchCounts: autoGeneratedReducer<{
        isLoad: boolean;
        value: number;

        matchingResultMap?: { [key: number]: { match_percentage: number } };
        usedResumeId?: number;
        isSuitableSearch?: boolean;
    }>({ isLoad: false, value: 0 }),
    searchClusters,
    searchDraftClusters,
    searchClustersOrder,
    searchDraftClustersOrder,
    searchDraft,
    searchGeoDrawer,
    searchQuery: autoGeneratedReducer<string>(null),
    lastUpdatedSearchCluster: autoGeneratedReducer<ClusterKey>(null),
    searchClustersTip,
    searchClustersTipAlternativePosition: autoGeneratedReducer<boolean>(false),
    searchSettings,
    searchExplanation,
    searchMapResponse,
    searchOldEducation: autoGeneratedReducer(false),
    searchQueries,
    searchSessionId: autoGeneratedReducer<string>(null),
    searchRating: autoGeneratedReducer(false),
    servicesCount,
    showEmployerVacancySearch: autoGeneratedReducer(false),
    isBrandSnippetInfoAllowed: autoGeneratedReducer(false),
    isEmployerVacancySearchAllowed: autoGeneratedReducer(false),
    similarSavedSearchEnabled: autoGeneratedReducer(false),
    scheduledInterviews,
    socialNetworksLinks,
    subscriptionInformer,
    surveys: autoGeneratedReducer<
        {
            id: string;
            text: string;
            url: string;
            userId: string;
        }[]
    >([]),
    suggestedKeySkills: autoGeneratedReducer<{ name: string; source?: string }[]>([]),
    employerRecommendedSkills,
    suggestedKeySkillsNewEngineProfessions: autoGeneratedReducer<string[]>([]),
    session,
    smilesShoudBeShown: autoGeneratedReducer(false),
    additionalCheck: autoGeneratedReducer(false),
    additionalCheckFirstView: autoGeneratedReducer(false),
    showArticleAreaSwitcher: autoGeneratedReducer(false),
    showEmployerRatingBlock: autoGeneratedReducer(false),
    showManagerContactsVacancyInfotip: autoGeneratedReducer(false),
    showResumeCountriesVisibilitySettingsModal: autoGeneratedReducer<boolean>(false),
    showWebimButtonOnLogin: autoGeneratedReducer(false),
    showResumeSearchConditions: autoGeneratedReducer(false),
    // temp_exp_PORTFOLIO-30998_nextline
    newSmbAnalyticsSend: autoGeneratedReducer(false),
    sortingFilters,
    smartScript,
    showEmployerStatisticsLinks: autoGeneratedReducer(false),
    supernovaMainMenu: autoGeneratedReducer<NavItem>({ name: '', url: '', translations: {} }),
    supernovaSearchView: autoGeneratedReducer<{
        large: boolean;
        largeXS: boolean;
        expanded: boolean;
    }>({ large: false, largeXS: false, expanded: false }),
    supernovaSearchName,
    supernovaSearchArea: autoGeneratedReducer({ translations: { area: '', geoExpName: '' } }),
    supernovaUserType,
    supportChat,
    stayInTouch,
    specialProject: specialProjectReducer,
    suitableCounterByVacancy,
    giftedResumes,
    isGiftedResume: autoGeneratedReducer<boolean>(null),
    suitableVacancies,
    targetAgenciesServiceInfo: autoGeneratedReducer({ areaTrl: '', profAreaTrl: '' }),
    topLevelDomain: autoGeneratedReducer<string>(null),
    topicsCallHistory,
    topLevelSite,
    topPromoBanner,
    trl,
    userType,
    userId: autoGeneratedReducer<string>(null),
    appLink,
    device,
    criteriaTextUpdated: autoGeneratedReducer<string | string[]>(''),
    criteriaExcludedTextUpdated: autoGeneratedReducer<string>(''),
    unsubscribe: autoGeneratedReducer<Record<string, string>>({}),
    unpremoderatedVacancyList,
    userStats,
    userFeatures: autoGeneratedReducer<string[]>(null),
    userLabelsForVacancies,
    userNotifications,
    // TODO Удалить после перехода всех микрофронтов на remoteEntry
    useRemoteEntryWay: autoGeneratedReducer<boolean>(false),
    userTargeting,
    userSearchPointsByResumeHash,
    prolongateInfo,
    vacancyAdvices: autoGeneratedReducer<VacancyAdvice[]>([]),
    vacanciesOfTheDay,
    vacancyConstructorExpirationDate: autoGeneratedReducer<number>(null),
    ...vacancyCreate,
    vacancyEmploymentFieldsDictionary: autoGeneratedReducer<VacancyEmploymentFieldsDictionary>(
        DEFAULT_VACANCY_EMPLOYMENT_FIELDS_DICTIONARY
    ),
    vacancyNewEmploymentFieldsEnabled: autoGeneratedReducer<boolean>(false),
    vacancyCompensationFieldsDictionary: autoGeneratedReducer<VacancyCompensationFieldsDictionary>(
        DEFAULT_VACANCY_COMPENSATION_FIELDS_DICTIONARY
    ),
    vacancyFeatures,
    vacancyFull,
    vacancySuitableResumes: autoGeneratedReducer<VacancySuitableResumes>(null),
    vacancySuitableResumesBannerState: autoGeneratedReducer<BannerState>(BannerState.Skeleton),
    vacanciesGroupsByRegion,
    vacanciesShort,
    vacancyInternalInfo,
    vacancyStatBannerData: autoGeneratedReducer<{ managersCount: number }>(null),
    managerVacancies,
    vacanciesInitialFilters: autoGeneratedReducer<VacanciesFilters>(DEFAULT_FILTERS_VALUES),
    vacanciesCount,
    vacanciesTabs,
    vacancyOnlineUsersCounters,
    vacancyCallsPage,
    vacancyPopularCities,
    vacancyOutcomingCallsPage,
    vacancyResponseFlags,
    vacancyAnalyticsConversion,
    vacancyHiringPlans,
    vacancyHiringPlanValidationRules: autoGeneratedReducer({}),
    vacancyMetro,

    // tempexp_PORTFOLIO-40783_next_line
    vacancyBenefits,

    vacancyChatInfo,
    otp: autoGeneratedReducer<Otp>({}),
    recaptcha: autoGeneratedReducer<RecaptchaType>({
        isBot: false,
        siteKey: null,
        captchaState: null,
        captchaError: false,
    }),
    hhcaptcha: autoGeneratedReducer<HhcaptchaType>({
        isBot: false,
        captchaState: null,
        captchaError: false,
        captchaKey: null,
    }),
    captchaText: autoGeneratedReducer<string>(''),
    vacancyResponsePopupVisible: autoGeneratedReducer(false),
    vacancySavedSearches,
    vacancySavedSearchUnsubscribeResult: autoGeneratedReducer<string>(''),
    vacancySavedSearchConfirmPageStatus: autoGeneratedReducer<'alreadyConfirmed' | 'fail' | 'error' | null>(null),
    vacancySearchDictionaries,
    vacancySearchResult,
    vacancyTests,
    vacancyView,
    vacancySearchSnippet,
    vacancyViewTab,
    vacancyWizard,
    vacancyTemplates,
    vacancyTemplateForEdit,
    vacancyViewsCount: autoGeneratedReducer<null>(null),
    vacancyConstructorMobilePreview: autoGeneratedReducer<{ templateId: string | null }>({
        templateId: null,
    }),
    vacancyConstructorSettings,
    vacancyConversionStatistics,
    vacation,
    vacancyResponsePopup,
    vacancyResponseSummary,
    vacancyResponseQuestions,
    vacancySummaryTopicId: autoGeneratedReducer<number>(null),
    vacancySearchSeenAnalytics,
    vacancyMessages,
    // tempexp_PORTFOLIO-38449_nextline
    valentineDayNotificationLink: autoGeneratedReducer('/'),
    verificationActs,
    verificationExpEnabled: autoGeneratedReducer<boolean>(false),
    newEmployerWithoutDocsClosedCandidatesBanner: autoGeneratedReducer(false),
    vrSignupLogin,
    vrVacancies,
    viewDuration,
    wantWorkEmployer: autoGeneratedReducer<{
        employerInfo: { id: number; name: string };
        employerLogo: { logoUrl: string | null };
    }>({ employerInfo: { id: NaN, name: '' }, employerLogo: { logoUrl: null } }),
    webcall,
    workInCity: autoGeneratedReducer({
        articleXmlStr: '',
        areaName: '',
    }),
    workInOtherCity,
    xsrfToken: autoGeneratedReducer(''),
    employerDivisionChangelog,
    serviceAdditional,
    supernovaAnonymousDashboard: anonymousDashboard,
    pinnedChatMessages,
    politenessIndex,
    preparedVacancyTemplates: autoGeneratedReducer<VacancyCreateTemplate[]>([]),
    snippetAccessResume,
    showPfpLandingBanner: autoGeneratedReducer<boolean>(false),
    zpEmployerOnboarding: autoGeneratedReducer<{ showOnboardingModal: boolean }>({ showOnboardingModal: false }),
    accountHistoryActionsData,
    accountHistoryMoney,
    scheduleInterviewRemindInfo: autoGeneratedReducer<TopicNegotiationInterviewInfo>(null),
    talantixTariff: autoGeneratedReducer<string>(null),
    useXhhLayout: autoGeneratedReducer<boolean>(true),
    webviewAppType: autoGeneratedReducer<string>(null),
    pfpLiquidityWarning,
    platformInfo,
    enableResumesMerge: autoGeneratedReducer<boolean>(false),
    isCookiesPolicyInformerVisible: autoGeneratedReducer<boolean>(false),
    employerCountryIdBySeller: autoGeneratedReducer<number>(null),
    anonymousSignUp,
    deepLinkCommandName: autoGeneratedReducer<DeepLinkCommandName>(null),
    showRelatedProfessionsSnippet: autoGeneratedReducer<boolean>(false),
    videoCallsDisabled: autoGeneratedReducer<boolean>(false),
    hideProfileBanner: autoGeneratedReducer(false),
    applicantProfile: applicantProfileStore,
    careerPlatformLinks: autoGeneratedReducer<CareerPlatformState>(null),
    careerPlatformLinksProxy: autoGeneratedReducer<CareerPlatformProxyState>(null),
    careerPlatformLinksForSkill: autoGeneratedReducer<CareerPlatformState>(null),
    timeZone: autoGeneratedReducer<string>(''),
    vacancyResponsesActiveScreen: autoGeneratedReducer<string>('response'),
    notes,
    allServices,
    vacancyFunnel,
    vacancyFunnelTemplates,
    enabledHidingForVacancyFunnelStages: autoGeneratedReducer<boolean>(true),
    applicantResumeViewHistory,
    isEmployerSubstatesExp: autoGeneratedReducer<boolean>(false),
    employerDeedsList,
    employerDeedOrder,
    employerDeedOrderModal,
    isHiringManagerExperiment: autoGeneratedReducer<boolean>(false),
    blockCloseMobileDrop: autoGeneratedReducer(false),
    latestResumeHash: autoGeneratedReducer<string>(null),
    showVacancyQuestionBanner: autoGeneratedReducer<boolean>(false),
    isChatQuestionExp: autoGeneratedReducer<boolean>(false),
    isVacancyQuestionSingleBlockExp: autoGeneratedReducer<boolean>(false),
    isVacancyQuestionDoubleBlockExp: autoGeneratedReducer<boolean>(false),
    isQuestionResponseCompletion: autoGeneratedReducer<boolean>(null),
    applicantAssessment: autoGeneratedReducer<ApplicantAssessment>(null),
    employerManagerMeta: autoGeneratedReducer<Partial<ManagersViewMeta>>({}),
    verificationReports: autoGeneratedReducer<VerificationReport[]>([]),
    disableChameleonHeader: autoGeneratedReducer<boolean>(null),
    hideDefaultSubscribe: autoGeneratedReducer<boolean>(null),
    isHiringManager: autoGeneratedReducer<boolean>(false),
    hiringManagerVacancyPermission: autoGeneratedReducer<HiringManagerVacancyPermission | null>(null),
    skillsVerificationMethodsPage: autoGeneratedReducer<{
        items: ApplicantKeyskillVerificationMethod[];
        isFromServices: boolean;
    }>({ items: [], isFromServices: false }),
    sentryExtraData: autoGeneratedReducer<Record<string, unknown>>({}),
    vacanciesWidget,
    applicantRenewedAuthExp,
    accountLogin,
    isUseMagritteLayout: autoGeneratedReducer<boolean>(false),
    isGeoSwitcherActive,
    defaultPaymentTypeForZp: autoGeneratedReducer<string>(''),
    vacancyToResumeSkillMatch,
    testsLibraries,
    testCategories,
    chatWidgetUrl: autoGeneratedReducer<string>(''),
    employerSuitablesUnsubscribe: autoGeneratedReducer<{ vacancyId: string; vacancyName: string; error: string }>({
        vacancyId: '',
        vacancyName: '',
        error: '',
    }),
    connectedApplicantServices: autoGeneratedReducer<ConnectedServiceMap>({}),
    archivedApplicantServices: autoGeneratedReducer<ConnectedServiceMap>({}),
    isTemplatesSelectFunnelInfotipVisible: autoGeneratedReducer<boolean>(true),
    isSelectHintTooltipVisible: autoGeneratedReducer<boolean>(true),
    reloadTemplates: autoGeneratedReducer<boolean>(false),
    addSkillToResume: autoGeneratedReducer<AddSkillToResume>(null),
    resumeEmployersList: employersListReducer,
    employerVacancyResponseTest,
    isEmployerAddressesLoading: autoGeneratedReducer<boolean>(false),
    isNoEmployerSubstatesOnboarding: autoGeneratedReducer<boolean>(false),
    employerServicesWidget,
    vacancyAdviceModalData,
    vacancyStatOnboardingOnList: autoGeneratedReducer<boolean>(false),
    vacancyStatOnboardingOnTab: autoGeneratedReducer<boolean>(false),
    vacancyStatOnboardingOnTabBlocks: autoGeneratedReducer<boolean>(false),
    isApplicantNegotiationsNewStateExp: autoGeneratedReducer<boolean>(false),
    waitBeforeSendTime: autoGeneratedReducer<number>(0),
    employerAdvicesWidget,
    employerBannersWidget,
    /**
     * Смотри src/utils/notLuxDataInterceptor.ts
     * @deprecated Используется для триггера событий вне реакт, не использовать внутри реакт
     */
    notLuxData: autoGeneratedReducer<never>(null),
    pushExternalAccessToken: autoGeneratedReducer<string>(''),
    isMagritteEmployerResumeView: autoGeneratedReducer<boolean>(false),

    isAutoresponseExp: autoGeneratedReducer<boolean>(false),
    isAutoresponseExpB: autoGeneratedReducer<boolean>(false),
    isAutoresponseExpC: autoGeneratedReducer<boolean>(false),
};

export type Store<R = typeof reducers> = { [K in keyof R]: R[K] extends ReducerObject<infer D, unknown> ? D : never };

export const store = createRootReducer(reducers);

export interface AppStore extends Store {
    router: RouterState;
}

export type NonNullableAppStore = NonNullableRootState<AppStore>;

// Используется для прокидывания типов в @hh.ru/redux-create-reducer
declare module 'react-redux' {
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    interface DefaultRootState extends AppStore {}
}

export default (history: History) =>
    combineReducers({
        ...store,
        router: connectRouter(history),
    });
